<template>
    <div>
        <div v-if="loading" class="receipt-container">
            <div class="text-center mt-5" style="justify-content: center;width: 100%;display: flex;overflow-y: scroll;">
                <div class="header-view">
                    <div style="font-size:20px;margin-top: 14px;">
                        {{ $t('pendingPayment') }}</div>
                </div>
                <div class="receipt-review">
                    <template v-if="paymentMethod == 'Card'">
                        <video class="mt-5 mb-3" width="30%" height="30%" preload="none"
                            style="opacity:0.8 ;background: transparent  url('https://cdn-icons-png.flaticon.com/512/15575/15575618.png') 50% 50% / fit no-repeat;"
                            autoplay="autoplay" loop="true" muted="muted" playsinline="">
                            <source src="https://cdn-icons-mp4.flaticon.com/512/15575/15575618.mp4" type="video/mp4">
                        </video><br>
                        <h4 class="mt-2">{{ $t('cardPayment') }}</h4>
                    </template>
                    <template v-else-if="paymentMethod == 'QR'">
                        <video class="mt-5 mb-3" width="30%" height="30%" preload="none"
                            style="opacity:0.8 ;background: transparent  url('https://cdn-icons-png.flaticon.com/512/15575/15575693.png') 50% 50% / fit no-repeat;"
                            autoplay="autoplay" loop="true" muted="muted" playsinline="">
                            <source src="https://cdn-icons-mp4.flaticon.com/512/15575/15575693.mp4" type="video/mp4">
                        </video><br>
                        <h4 class="mt-2">{{ $t('QRPayment') }}</h4>
                    </template>
                    <template v-else>
                        <video class="mt-5 mb-3" width="30%" height="30%" preload="none"
                            style="opacity:0.8 ;background: transparent  url('https://cdn-icons-png.flaticon.com/512/13121/13121501.png') 50% 50% / fit no-repeat;"
                            autoplay="autoplay" loop="true" muted="muted" playsinline="">
                            <source src="https://cdn-icons-mp4.flaticon.com/512/13121/13121501.mp4" type="video/mp4">
                        </video><br>
                        <h4 class="mt-2">{{ $t('pendingPayment') }}</h4>
                    </template>



                    <small> {{ $t('receiptNumber') }} : {{ receiptObjectId }}</small><br>
                    <CAlert style="font-size: medium;" v-if="messagebox1 != ''" class="mt-3" color="primary">{{
            messagebox1 }}
                    </CAlert>
                    <div style="font-size: larger;" class="row p-3">
                        <div v-if="paymentMethod == 'QR'" class="col-6 text-left mt-2">{{ $t('paymentType') }}</div>
                        <div v-else-if="paymentMethod == 'Card'" class="col-6 text-left mt-2">{{ $t('paymentType') }}
                        </div>

                        <div v-if="paymentMethod == 'QR'" class="col-6 text-right mt-2">QR Payment </div>
                        <div v-else-if="paymentMethod == 'Card'" class="col-6 text-right mt-2">Credit Card</div>


                        <div class="col-6 text-left mt-2">{{ $t('tableNumber') }}</div>
                        <div class="col-6 text-right mt-2"> {{ tableName }} </div>

                        <div class="col-6 text-left mt-2">{{ $t('countProduct') }}</div>
                        <div class="col-6 text-right mt-2"> {{ receiptItems.length }} </div>
                        <div class="col-6 text-left mt-2">{{ $t('netAmount') }}</div>
                        <div class="col-6 text-right mt-2">{{ convertCurrency(netAmount) }}</div>

                        <template v-if="!isVatInclude">
                            <div class="col-6 text-left mt-2">{{ $t('netAmountVATTotal') }}</div>
                            <div class="col-6 text-right mt-2">{{ convertCurrency(netAmountVATTotal) }} ฿</div>
                            <div class="col-8 text-left mt-2">{{ $t('vatAmount') }} {{ vatBase }}%</div>
                            <div class="col-4 text-right mt-2">{{ convertCurrency(vatAmount) }} ฿</div>

                        </template>
                        <template v-if="serviceCharge > 0">
                            <div class="col-8 text-left mt-2">{{ $t('serviceCharge') }}</div>
                            <div class="col-4 text-right mt-2">{{ convertCurrency(serviceCharge) }} ฿</div>
                        </template>
                        <template v-if="discount > 0">
                            <div class="col-7 text-left mt-2">{{ $t('discount') }}</div>
                            <div class="col-5 text-right mt-2">- {{ convertCurrency(discount) }} ฿</div>
                        </template>

                        <div class="col-6 text-left mt-4 h4">
                            {{ $t('grandTotal') }}
                        </div>
                        <div class="col-6 text-right mt-4 h4">
                            {{ convertCurrency(grandTotal) }}฿
                        </div>

                    </div>
                    <div v-if="PayPermission == true" class="mt-2 mb-2">
                        <CButton style="font-size: large;" @click="cancelPayStatus()" color="danger">
                            <CSpinner v-if="loadingCancelStatus" class="mb-1" component="span" size="sm"
                                aria-hidden="true" />
                            &nbsp; {{ $t('cancelPayment') }}
                        </CButton>
                    </div>

                    <br>
                    <span>• {{ $t('canNotOrderItem') }}</span><br><br>

                    <span>• {{ $t('haveNotPurchased2') }}</span>
                    <br>
                    <div class="mb-5"></div>


                    <span class="mt-4">
                        Powered by
                        <img src="/silom-pos.png" width="5%" alt="" class="img-fluid" />
                        Silom POS
                    </span>
                </div>
                <footer @click="BackToMenu()" class="fixed-bottom"
                    style="padding: 20px;background-color:#219b78;color: #ffffff;">
                    <div style="font-size: large;">
                        <CSpinner v-if="loadingStatus" class="mb-1" component="span" size="sm" aria-hidden="true" />
                        &nbsp;
                        {{
            $t('checkPaymentStatus') }}
                    </div>
                </footer>
            </div>

        </div>
        <div v-else class="text-center receipt-container"
            style="justify-content: center;width: 100%;display: flex;overflow-y: scroll;">
            <div class="header-view">
                <div style="font-size:20px;margin-top: 14px;">
                    {{ $t('paymentSuccess') }}</div>
            </div>
            <div class="receipt-review" style="margin-top: 350px;margin-bottom: 300px;">
                <img style="z-index: 1080;" src="   https://cdn-icons-png.flaticon.com/512/4315/4315445.png "
                    width="25%" alt="" title="" class="img-fluid mt-5 mb-3"><br>
                <h2> {{ branchName }}</h2>
                <small> {{ $t('receiptNumber') }} : {{ receiptObjectId }}</small><br>
                <div style="font-size: larger;" class="row p-3">
                    <div class="col-6 text-left mt-2">{{ $t('paymentType') }}</div>
                    <div class="col-6 text-right mt-2"> Credit Card </div>

                    <div class="col-6 text-left mt-2">{{ $t('tableNumber') }}</div>
                    <div class="col-6 text-right mt-2"> {{ tableName }} </div>

                    <div class="col-6 text-left mt-2">{{ $t('countProduct') }}</div>
                    <div class="col-6 text-right mt-2"> {{ receiptItems.length }} </div>

                    <template v-if="!isVatInclude">
                        <div class="col-6 text-left mt-2">{{ $t('netAmountVATTotal') }}</div>
                        <div style="white-space: nowrap;" class="col-6 text-right mt-2">{{
            convertCurrency(netAmountVATTotal) }} ฿</div>
                        <div class="col-8 text-left mt-2">{{ $t('vatAmount') }} {{ vatBase }}%</div>
                        <div style="white-space: nowrap;" class="col-4 text-right mt-2">{{ convertCurrency(vatAmount) }}
                            ฿</div>

                    </template>
                    <template v-if="serviceCharge > 0">
                        <div class="col-8 text-left mt-2">{{ $t('serviceCharge') }}</div>
                        <div style="white-space: nowrap;" class="col-4 text-right mt-2">{{
            convertCurrency(serviceCharge) }} ฿</div>
                    </template>
                    <template v-if="discount > 0">
                        <div class="col-7 text-left mt-2">{{ $t('discount') }}</div>
                        <div style="white-space: nowrap;" class="col-5 text-right mt-2">- {{ convertCurrency(discount)
                            }} ฿</div>
                    </template>

                    <div class="col-6 text-left mt-4 h4">
                        {{ $t('grandTotal') }}
                    </div>
                    <div class="col-6 text-right mt-4 h4">
                        {{ convertCurrency(grandTotal) }}฿
                    </div>

                </div>
                <div class="mb-2 mt-3">{{ $t('askForReceipt') }}</div><br>
                <span class="mt-4">
                    Powered by
                    <img src="/silom-pos.png" width="5%" alt="" class="img-fluid" />
                    Silom POS
                </span>
            </div>

        </div>

    </div>
</template>

<script>
import '@/util/menu.css'
import poscrm from '@/services/poscrm'
import util from '@/util/util'


export default {
    components: {

    },
    data() {
        return {
            title: '',
            msgError: '',
            msgSuccess: '',
            alert: '',
            delayAlert: 3,
            loadingButton: false,
            source: '',
            document: null,
            tableName: '',
            grandTotal: 0,
            receiptItems: [],
            branchName: '',
            loading: true,
            intervalId: null,
            messagebox1: '',
            loadingStatus: false,
            netAmountVATTotal: 0,
            isVatInclude: true,
            vatBase: '',
            vatAmount: 0,
            serviceCharge: 0,
            isVATIncluded: Boolean,
            PayPermission: false,
            loadingCancelStatus: false,
            receiptId: '',
            discount: 0,
            netAmount: 0,
        };
    },

    computed: {
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        receiptObjectId() {
            return `${localStorage.getItem('receiptObjectId')}`
        },
        shopObjectId() {
            return `${localStorage.getItem('shopObjectId')}`
        },
        tableObjectId() {
            return `${localStorage.getItem('tableObjectId')}`
        },
        paymentMethod() {
            return `${localStorage.getItem('creditCardPayStatus')}`
        },

    },
    mounted() {
        this.intervalId = setInterval(this.getReceiptTransaction, 3000)
    },
    created() {
        this.loadingCancelStatus = false,
            this.loadingStatus = false,
            this.getReceiptTransaction()
        this.PayPermission = localStorage.getItem('paymentStatus') === 'true'
        if (this.paymentMethod == 'S') {
            this.$router.push('/dine-in/menulists')
        }
    },
    methods: {
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'make_payment', {
                    event_label: buttonLabel,
                });
            } else {
                console.error('gtag is not defined.');
            }
        },
        convertCurrency(price) {
            return util.convertCurrency(Number(price))
        },
        async cancelPayStatus() {
            this.trackButtonClick('Cancel Payment')
            this.loadingCancelStatus = true
            let timestamp = localStorage.getItem('timestamp')
            localStorage.setItem('creditCardPayStatus', '');
            localStorage.setItem('paymentStatus', false);
            if (this.receiptId !== undefined && this.receiptId !== null) {
                let data = {
                    objectId: this.receiptObjectId,
                    uid: this.uid,
                    shopObjectId: this.shopObjectId,
                    receipt: {
                        source: 'SELF_ORDER',
                        QRTimestamp: timestamp,
                        receiptStatus: 'O',
                        table: {
                            tableStatus: 'O',
                        },
                        receiptItems: this.receiptItems,
                        objectId: this.receiptObjectId,
                        id: this.receiptId,
                        isVATIncluded: this.isVATIncluded,
                    },
                }
                try {
                    await poscrm({
                        url: '/api/v1.1/' + this.uid + '/Receipt/updateorder',
                        data: data,
                        method: 'post',
                    }).then((res) => {
                        console.log('Unlocked Payment')
                    })
                } catch (error) {
                    throw new Error(error)
                }

            }
        },
        BackToMenu() {
            this.messagebox1 = this.$i18n.t('haveNotPurchased')
            this.messagebox2 = this.$i18n.t('haveNotPurchased2')
            this.loadingStatus = true
            this.getReceiptTransaction()
            setTimeout(() => {
                this.messagebox1 = ''
                this.loadingStatus = false
            }, 3000);
        },

        async getReceiptTransaction() {

            try {
                await poscrm
                    .get('/api/v1.1/' + this.uid + '/Receipt/data/' + this.receiptObjectId)
                    .then((res) => {
                        let document = res.data.data.document
                        if (document.length !== 0) {
                            this.tableName = document.table.name || document.tableName
                            this.grandTotal = document.grandTotal.toFixed(2)
                            this.receiptItems = document.receiptItems
                            this.branchName = document.shop.branchName
                            this.netAmountVATTotal = document.netAmountVATTotal.toFixed(2)
                            this.isVatInclude = document.isVATIncluded
                            this.vatBase = document.vatBase
                            this.vatAmount = document.vatAmount.toFixed(2)
                            this.serviceCharge = document.serviceCharge.toFixed(2)
                            this.isVATIncluded = document.isVATIncluded
                            this.discount = document.discountAmount.toFixed(2)
                            this.netAmount = document.netAmount.toFixed(2)

                            if (document.receiptStatus == 'A') {
                                let paymentMethod = localStorage.getItem('creditCardPayStatus')
                                this.trackButtonClick('Payment Success_by_' + paymentMethod)
                                this.loading = false
                                clearInterval(this.intervalId);
                                localStorage.setItem('creditCardPayStatus', 'S')
                                localStorage.removeItem('paymentStatus')
                            }
                            else if (document.table.tableStatus == 'O') {
                                this.$router.push('/dine-in/menulists')
                            }
                            this.receiptId = document.order.receiptId
                        }

                    })
            } catch (error) {
                throw new Error(error)
            }


        },
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
};
</script>

<style>
.receipt-review {
    margin-top: 40px;
    margin-bottom: 300px;
    width: 80%;
    padding-bottom: 70px;
    background-color: #ffffff !important;
    border-radius: 5px;
    align-self: center;
    -webkit-mask-image: radial-gradient(circle at 50% 8px, transparent 8px, red 8.5px);
    -webkit-mask-position: 50% -8px;
    -webkit-mask-size: 16px 100%;
}

.header-view {
    background-color: #219b78;
    color: #ffffff;
    height: 60px;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2000;
}

.receipt-container {
    background-color: #eeeeee;
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0%;
    top: 0px;
    overflow-y: scroll;
}
</style>